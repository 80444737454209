export const dict = {
  account: {
    _: "Account",
    login: "Login",
    register: "Register",
    email: {
      _: "Email",
      confirm: {
        title: "Confirm email",
        message: "You account has been created. You need to confirm your email address before you can login",
      },
    },
    password: "Password",
    passwordMinLength: (minLength: number) => `The password must have a length of at least ${minLength} characters`,
    resetPassword: "Reset password",
    passwordRepeat: "Repeat password",
    name: "Name",
    surname: "Surname",
    phoneNumber: "Phone number",
    passwordResetInstructions: "We will send instructions on how to reset your password to this email",
    sixdigitcode: "6-Digit Code",
    codeVerifyFailed:
      "Failed to verify the code. This code is only valid for a limited period of time and can only be used once. Please try again.",
    logout: "Logout",
    logoutFail: "Failed to sign out",
    create: "Create account",
    alreadyHave: "I already have an account",
    statusBeforeLogout: "Set your status to 'out of service' before logging out",
    changePassword: "Change password",
    delete: {
      _: "Delete account",
      warning:
        "After you enter your password your account will be marked for deletion and will be deleted by an administrator. This action is irreversible.",
      confirmation: {
        title: "Account deleted",
        message:
          "Your account has been marked for deletion and will be deleted by an administrator. We will send you an email when this process is complete. If this was a mistake, please immediately contact us: info@mooovex.com",
      },
      error: "Failed to delete your account. Check your password",
    },
    created: {
      title: "Thank you for registering with Mooovex",
      message: (email: string) =>
        `To activate your account, please follow the instructions you received via email at ${email}`,
    },
  },
  validation: {
    passwordMinLength: "The password must be at least 8 characters long",
    emailInvalid: "Invalid E-Mail",
    passwordsDontMatch: "Passwords don't match",
    requiredField: "Required",
    invalidPhone: "Invalid phone number",
    login: {
      error: "Login failed, check E-Mail and password",
    },
    register: {
      error: "Registration failed, try again later",
      emailAlreadyExists:
        "An account with this E-Mail address already exists. If you don't remeber the password, you can reset it.",
    },
    passwordChange: {
      success: "Your password has been changed successfully",
      error: "Password change failed",
    },
    passwordResetFail: "Password reset failed, check E-Mail",
    passwordResetSuccess: (email: string) =>
      `If an account with the E-Mail address ${email} exists, an E-Mail with instructions on how to reset the password has been sent to it`,
  },
  common: {
    yes: "Yes",
    no: "No",
    ok: "OK",
    abort: "Abort",
    troubleshooting: "Troubleshooting",
    noResults: "No results",
    allow: "Allow",
    cancel: "Cancel",
    continue: "Continue",
    back: "Back",
    user: "User",
    company: "Company",
    companies: "Companies",
    yourCompanies: "Your companies",
    language: "Language",
    date: "Date",
    time: "Time",
    default: "Default",
    normal: "Normal",
    rightClickToCopy: "Right click to copy",
    tryAgain: "Try again",
    error: "Error",
    close: "Close",
    save: "Save",
    name: "Name",
    email: "Email",
    phoneNumber: "Phone Number",
    reset: "Reset",
    delete: "Delete",
    refresh: "Refresh",
    loading: "Loading",
  },
  ride: {
    duration: "Duration",
    distance: "Distance",
    notriptext: "Fill in the startpoint and enpoint of the route to get the details",
    reset: { _: "Reset trip" },
    start: { _: "Start trip" },
    conclude: {
      _: "Complete trip",
      confirm: "Are you sure you want to complete the trip?",
      error: "Failed to conclude the trip",
    },
    abort: {
      _: "Abort trip",
      confirm: "Are you sure you want to abort the trip?",
    },
    waypoint: "Waypoint",
    stopover: { _: "Stopover" },
    resume: { _: "Resume trip" },
    origin: {
      _: "Origin",
      keep: {
        title: "Origin already set",
        message: "You've already set an origin. Do you want to keep it or use your current location?",
      },
    },
    destination: {
      _: "Destination",
      keep: {
        title: "Destination already set",
        message: "You've already set a destination. Do you want to keep it or use your current location?",
      },
    },
    personCount: "Person count",
    currentTime: "Current time",
    currentDate: "Current date",
    calculatingRoute: "Calculating route",
    currentLocation: "Current location",
    failedToGetCurrentLocation: "Failed to get current location",
    route: {
      calculation: {
        error: "Failed to calculate route",
      },
    },
    place: {
      keep: "Keep",
      useCurrentLocation: "Use current location",
    },
  },
  price: {
    discount: "Discount",
    feedback: {
      _: "Price feedback",
      tooHigh: "Too high",
      tooLow: "Too low",
    },
    calculation: {
      _: "Pricecalculation",
      error: "Failed to calculate price",
    },
    pricelist: "Pricelist",
  },
  vehicleSelection: {
    companyAndVehicle: "Company and vehicle",
    company: "Company",
    vehicle: "Vehicle",
    vehicleModel: "Model",
    noCompanies: "No companies available",
    noVehicles: "No vehicles available",
    noCompany: "No company",
    noVehicle: "No vehicle",
    availableCompanies: {
      error: "Failed to load available companies",
    },
    availableVehicles: {
      error: "Failed to load available vehicles",
    },
    selectVehicle: {
      error: "Failed to select vehicle",
    },
  },
  driverlist: {
    noDrivers: "No drivers",
    drivers: "Drivers",
    visibleDrivers: {
      error: "Failed to load drivers",
    },
    noZipcode: "No zipcode",
  },
  network: {
    noInternet: "No internet connection",
  },
  driver: {
    setStatus: {
      error: "Failed to set the status",
    },
    status: {
      notOutofservice: {
        title: "Status is not out of service",
        message: "Please set your status to 'out of service' before signing out",
      },
    },
    vehicle: {
      noVehicleChosen: {
        title: "Choose vehicle",
        message: "You need to choose a vehicle to go online",
      },
    },
  },
  poi: {
    fetch: {
      error: "Failed to load places",
    },
    google: {
      error: "Failed to load google places",
    },
  },
  user: {
    info: {
      error: "Failed to load user info",
    },
  },
  permissions: {
    geolocation: {
      backgroundPermissionRationale: {
        title: "Allow {applicationName} to access your location in the background",
        message:
          "Always allowing location services will ensure your location can be shared reliably even when Mooovex is running in the background.",
      },
      denied: "Open the application settings and change the permission manually",
      rationale: {
        title: "Turning on location features allows us to",
        features: {
          shareLocation: "Share your location with drivers in your company",
          assignRides: "Assign you rides closest to you",
          calculateRoutes: "Calculate the most optimized routes for your rides",
        },
        backgroundInfo:
          "Additionally, allowing location access while the app is running in the background greatly enhances the reliability of the features mentioned above when your screen is off or you have another app in the foreground.",
        changeLater: "You can change this option later in the settings",
      },
      alwaysWarning: `To share your location, please set your location permission to "Always"`,
    },
    openSettings: "Open settings",
    allowBackground: "Change to '{backgroundPermissionOptionLabel}'",
  },
  taxistations: "Taxistations",
  geolocation: {
    notification: {
      title: "Location Tracking",
      message: "Mooovex is tracking you location. To turn off location tracking, set your status to 'out of service'",
    },
    get: {
      failed: "Failed to get your location",
    },
    enableInSettings: "Enable location services in the settings",
  },
  devices: {
    _: "Devices",
    noDevices: "No devices",
    thisDevice: "This device",
    override: {
      title: "Send vehicle location with this device?",
      message:
        "Currently another device connected to your account is sending the location of the vehicle. Would you like your device to send the location of the vehicle instead of the other device?",
    },
  },
  transportcompany: {
    logout: { _: "Sign out of company", fail: "Failed to sign out of company" },
    failedToChoose: "Failed to choose Company",
    failedToUpdateGlobalStatusFilter: "Failed to update global status filter",
    failedToUpdateVisibility: "Failed to update visibility",
  },
  vehicle: {
    deselect: "Release vehicle",
  },
  updates: {
    new: {
      title: (version: string) => `Update ${version} available`,
      message:
        "A new update is available, the app needs to restart in order to download and install this update. Restart now?",
    },
    appStore: {
      title: "App Store update available",
      message: (versionString: string) => `Please download version ${versionString} from the App Store`,
      button: "Open App Store",
    },
    searchingForUpdates: "Searching for updates",
    downloadingUpdate: (version: string) => `Downloading update ${version}`,
    installingUpdate: (version: string) => `Installing update ${version}`,
  },
  place: {
    search: {
      favorites: "Favorites",
      history: "History",
      results: "Search results",
    },
    airportHotelAddress: "Airport, Hotel, Address...",
    couldNotFindPlaceName: "Could not find place name",
    failedToGetDetails: "Failed to get place details",
    failedToGetPlaces: "Failed to get places",
  },
  service: "Service",
  settings: {
    _: "Settings",
    biometrics: {
      _: "Biometrics",
      deleteCredentials: "Delete biometric credentials",
      setup: {
        title: "Setup biometrics",
        message: "By setting up biometric verification you can login without your password",
      },
      failedToSaveBiometricCredentials: "Failed to save biometric credentials",
      savedCredentials: "Saved credentials",
      verificationFailed: "Verification Failed",
      noCredentialsFound: "No credentials found",
      loginWithBiometrics: "Login with Biometrics",
      logoutForBiometricSetup: "You will be logged out in order to setup biometrics",
      delete: {
        title: "Do you want to delete your biometric credentials?",
        message: "You can no longer login with biometrics",
        confirm: "Biometric credentials deleted",
        failed: "Failed to delete Biometric credentials",
      },
    },
    replaceLocation: "Replace location on ride start/stop",
    driverstatusInDropdown: "Status selector inside the bottom menu",
    rideFormLandscapeRightSide: "Show form on the right in landscape",
    largerVehicleIcons: "Larger vehicle icons",
    hideConcludeConfirm: "Hide conclude transfer confirmation",
    debugMode: "Debug Mode",
  },
  locale: {
    failedToChange: "Failed to change locale",
  },
  transfers: {
    transferCreateError: "Failed to create transfer",
    waypointCreateError: "Failed to create waypoint",
    transferPauseError: "Failed to pause transfer",
    transferResumeError: "Failed to resume transfer",
    waypointSetWaitTimeError: "Failed to set wait time",
    transferConcludeError: "Failed to conclude transfer",
    transferAbortError: "Failed to abort transfer",
    routeSegmentGenerateError: "Failed to generate route segment",
    delete: {
      confirm: {
        title: "Delete transfer",
        message: "Are you sure you want to delete this transfer?",
      },
      error: "Failed to delete transfer",
    },
    saved: "Transfer has been saved",
    deleted: "Transfer has been deleted",
  },
  transferSummary: {
    paymentMethod: {
      cash: "Cash",
      card: "Card",
      invoice: "Invoice",
    },
    comments: "Comments",
    testMode: "Test Mode",
    client: "Client",
    invoiceTo: "Invoice To",
    priceOptions: {
      _: "Price options",
      feedback: {
        _: "Price Feedback",
        tooHigh: "Too High",
        tooLow: "Too Low",
      },
      actualPrice: "Actual Price",
      description:
        "Mark if the price is too high or too low, and optionally enter a corrected fare for significant discrepancies. Your feedback helps improve our pricing accuracy",
    },
    open: "Open Summary",
  },
  partnerships: {
    _: "Partnerships",
    description: "View and manage your GPS data sharing partnerships with other companies and groups",
    noPartnerships: "No Partnerships",
    active: "Active",
    inactive: "Inactive",
    sender: "Sender",
    status: "Status",
    receiver: "Receiver",
    any: "Any",
    shareMyPositionWith: "Share my position with",
    sharesPositionWithMe: "Shares position with me",
    partner: "Partner",
    request: "Request",
    share: "Share",
    editPartnership: "Edit Partnership",
    shareStatus: "Share Status",
    failedToUpdatePartnership: "Failed to update partnership",
    closePartnership: "Close Partnership",
    oneStatusRequired: "One or more statuses are required",
    globalStatus: "Global status",
    globalStatusDescription: "Status to be shared when global visibility is selected",
  },
  driverlocations: {
    failedToFetch: "Failed to fetch driver locations",
  },
};

export type Dict = typeof dict;
